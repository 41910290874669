

export default {
  data() {
    return {
      openCart: false,
      openUnderStickyMenu:false,
      search: "",
      cart: { items: [] },
      searchFocus: false,
      selected: {},
      notSticky:true,
      selected_nested: {},
      mega_menu: this.prepareMega(),
    };
  },
  watch: {
    "$route.name": {
      handler(v, o) {
        this.openUnderStickyMenu = false;
      },
    },
    "$route.params": {
      handler(v, o) {
        this.openUnderStickyMenu = false;
      },
    },
    "$store.state.sideFilters": {
      handler(v, o) {
        this.search = v.q;
        this.$forceUpdate();
      },
      deep: true,
    },
    openCart: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.cart = this.$hyperCart.getCart();
    this.$nuxt.$on("updateCart", () => {
      this.cart = this.$hyperCart.getCart();
    });
  },
  methods: {
    handleScroll() {
      if (!document.getElementById("beHeader")) {
        return;
      }
      if (
        window.pageYOffset >
        (screen.width < 600
          ? 32
          : document.getElementById("beHeader").offsetHeight)
      ) {
        if (this.notSticky) this.notSticky = false;
      } else {
        if (!this.notSticky) this.notSticky = true;
      }
    },
    prepareMega() {
      let temp = JSON.parse(
        JSON.stringify(this.$store.getters.store.eshop.mega_menu)
      );
      temp = temp.map((c) => {
        let full = false;
        c.children.forEach((cc) => {
          if (cc.children && cc.children.length) {
            full = true;
          }
        });
        c.full = full;
        return c;
      });
      return temp;
    },
    selectChild(b) {
      this.selected = JSON.parse(JSON.stringify(b));
      this.$forceUpdate();
    },
    doSearch() {
      let query = [];
      if (this.search) {
        query.push(`q=${this.search}`);
      }

      this.searchFocus = false;
      this.$router.push({ path: `/search/+${query.join("+")}` });
    },
  },
};
